import $ from 'jquery';
import 'react';
import 'react-dom';
import lozad from 'lozad';
import 'moment';

require('popper.js');
require('bootstrap');
require('@rails/ujs').start();

$(() => {
  I18n.locale = window.appConfig.locale;

  const asyncImg = lozad('.js-async-img', { rootMargin: '400px 0px' });
  asyncImg.observe();

  import('pages/front').then(({ default: Pages }) => Pages());
});

$(() => {
  $('[data-toggle="tooltip"]').tooltip();
});
